import React, { useEffect, useRef } from "react"
import { playPopupAnimation } from "../popup/animation"
import ctl from "@netlify/classnames-template-literals"
import Form from "./form"

const DownloadPopup = ({ closePopup, isOpen }) => {
  const popup_container = useRef()
  const popup_content = useRef()

  useEffect(() => {
    playPopupAnimation(popup_container.current, !isOpen, popup_content.current)
  }, [isOpen])

  const popupContainerStyle = ctl(`
  transform 
  h-screen 
  w-full 
  bg-white 
  bg-opacity-30 
  backdrop-filter 
  backdrop-blur-sm  
  fixed 
  z-50 
  top-0 
  left-0  
  items-center 
  justify-center 
  flex
  `)
  const wrapperStyle = ctl(`
  bg-black
  text-white
  xl:pl-[129px]
  xl:pr-[188px]
  sm:pl-[64px]
  sm:pr-[120px]
  md:pt-[89px]
  md:pb-[75px]
  py-[48px]
  pl-[23px]
  pr-[18px]
  relative
  md:w-[70%]
  sm:w-[80%]
  w-[90%]
  `)

  return (
    <section className={popupContainerStyle} ref={popup_container}>
      <div ref={popup_content} className={wrapperStyle}>
        <Form closePopup={closePopup} />
      </div>
    </section>
  )
}

export default DownloadPopup
