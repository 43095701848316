import ctl from "@netlify/classnames-template-literals"
import React from "react"

const MainFooter = () => {
  const footer_style = ctl(`
  lg:pl-64
  `)
  const footer_wrapper_style = ctl(`
  py-8 
  bg-black 
  text-white 
  md:mt-0 
  mt-10
  md:pr-24 
  pr-7
  `)

  const paragraph_style = ctl(`
  text-right 
  mt-0
  `)

  return (
    <footer className={footer_style}>
      <div className={footer_wrapper_style}>
        <p className={paragraph_style}>
          &copy;{" "}
          <a href="https://fourthcanvas.co" target="_blank" rel="noreferrer">
            FourthCanvas
          </a>{" "}
          {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  )
}

export default MainFooter
