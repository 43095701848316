import React, { useState } from "react"
import CloseButton from "../popup/close-button"
import Button from "../button"
import ctl from "@netlify/classnames-template-literals"
import send_report from "../../../helpers/mail/send-report"

const Form = ({ closePopup }) => {
  const [name, setName] = useState("")
  const [organization, setOrganization] = useState("")
  const [designation, setDesignation] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  // clear all form fields
  const clearAllFormFields = () => {
    setName("")
    setOrganization("")
    setDesignation("")
    setEmail("")
  }

  // things to do after user's data is sent to mailchimp
  const actionAfterSuccess = async (email, firstname) => {
    // check if the report was sent

    try {
      await send_report({ email: email, firstname: firstname })
      clearAllFormFields()
      setMessage("🎉 Kindly check your email for your copy of the report")
      setTimeout(() => {
        setMessage("")
      }, 5000)
      setLoading(false)
    } catch (error) {
      setMessage("😔 Kindly try again")
      setLoading(false)
    }
  }

  const actionAfterError = () => {
    setMessage("😔 Something went wrong, kindly try again")
    setLoading(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const fullName = name.split(" ")
    const firstName = fullName[0]
    const lastName = name.substring(fullName[0].length).trim()

    const person = {
      email_address: email,
      merge_fields: {
        FNAME: firstName,
        LNAME: lastName,
        DSIGNATION: designation,
        ORG: organization,
      },
    }

    setLoading(true)
    try {
      await fetch(`/api/mailchimp/get-subscriber`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      })
        .then(res => res.json())
        .then(data => {
          // I.e the user was not found meaning they are not subscribed
          if (data.error) {
            // Add them to the newsletter
            fetch(`/api/mailchimp/add-subscriber`, {
              method: "POST",
              body: JSON.stringify(person),
            })
              .then(response => response.json())
              .then(data => {
                actionAfterSuccess(email, firstName)
              })
              .catch(err => {
                actionAfterError()
              })
          } else {
            // Update their details
            person.MEMBER_HASH = data.id
            fetch(`/api/mailchimp/update-subscriber`, {
              method: "PUT",
              body: JSON.stringify(person),
            })
              .then(response => response.json())
              .then(data => {
                actionAfterSuccess(email, firstName)
              })
              .catch(error => {
                actionAfterError()
              })
          }
          clearAllFormFields()

          return data
        })
        .catch(err => {
          actionAfterError()
        })
    } catch (error) {
      actionAfterError()
    }
  }
  const formInputStyle = ctl(`
    bg-black
    text-white
    lg:w-[97%]
    sm:w-[99%]
    w-[90%]
    focus:outline-none
    pb-2
    mb-[41px]
    border-b
    border-white
    `)
  return (
    <form onSubmit={handleSubmit} className="relative" id="download-report-form">
      <input
        type="text"
        name="name"
        placeholder="First name"
        className={`${formInputStyle} form-input`}
        onChange={e => setName(e.target.value)}
        value={name}
        required
      />{" "}
      <br />
      <input
        type="text"
        name="organization"
        placeholder="Company"
        className={`${formInputStyle} form-input`}
        onChange={e => setOrganization(e.target.value)}
        value={organization}
        required
      />
      <br />
      <input
        type="text"
        name="designation"
        placeholder="Job title"
        className={`${formInputStyle} form-input`}
        onChange={e => setDesignation(e.target.value)}
        value={designation}
        required
      />
      <br />
      <input
        type="email"
        name="email"
        placeholder="Work email"
        className={`${formInputStyle} form-input`}
        onChange={e => setEmail(e.target.value)}
        value={email}
        required
      />
      <br />
      <Button
        text={loading ? "Hang on a sec..." : "Download report"}
        disabled={loading}
        extraStyle={`bg-red`}
        submit={"submit"}
      />
      <p>{message}</p>
      <CloseButton
        closePopup={closePopup}
        extraStyle={`lg:top-[-10px]  md:top-[-14px] top-[6px] 2xl:right-[-7px] lg:right-[-35px] md:right-[-45px] sm:right-[-25px] right-[10px]`}
      />
    </form>
  )
}

export default Form
