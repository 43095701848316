import React from "react"
import PopupClose from "../../icons/popup-close"

const CloseButton = ({ closePopup, extraStyle }) => {
  return (
    <button
      className={`group bg-pink md:w-14 w-8  md:h-12 h-7 inline-flex rounded-none items-center justify-center focus:outline-none absolute ${extraStyle}`}
      onClick={() => {
        closePopup()
      }}
      name="Close popup"
    >
      <span className="transform inline-block duration-500 group-hover:rotate-180">
        <PopupClose />
      </span>
    </button>
  )
}

export default CloseButton
