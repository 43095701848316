import React from "react"
import PropTypes from "prop-types"
import WaitlistArrow from "../../icons/waitlist-arrow"
import ctl from "@netlify/classnames-template-literals"

const Button = ({ text, submit, disabled, link, extraStyle }) => {
  const buttonStyle = ctl(`
  group 
  bg-black 
  text-white 
  px-8 
  pt-2.5 
  inline-flex 
  items-center 
  md:justify-start 
  justify-center  
  md:pb-4
  pb-3.5
  rounded-none 
  leading-none 
  md:w-auto 
  w-full
  focus:outline-none
  ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
  `)

  const waitlistArrow = (
    <span className="transform duration-300 cur inline-block ml-5  mt-1 group-hover:translate-x-3">
      <WaitlistArrow />
    </span>
  )
  const buttonMarkup = (
    <button
      type={submit ? "submit" : "button"}
      className={`${buttonStyle} ${extraStyle}`}
      disabled={disabled}
      aria-label={text}
    >
      {text}
      {waitlistArrow}
    </button>
  )

  const linkMarkup = (
    <a
      href={link}
      className={`${buttonStyle} ${extraStyle}`}
      aria-label={text}
      target="_blank"
      rel="noreferrer"
    >
      {text} {waitlistArrow}
    </a>
  )
  return <>{link ? linkMarkup : buttonMarkup}</>
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Button
