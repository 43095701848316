import { TimelineMax } from "gsap"

export const playPopupAnimation = (container, reverse, popup_children) => {
  const popup_tl = new TimelineMax({ paused: true })

  popup_tl
    .fromTo(
      container,
      {
        scale: 0,
      },
      {
        scale: 1,
        duration: 0.1,
      }
    )
    .fromTo(
      container,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
      }
    )
    .fromTo(
      popup_children,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 0.75,
        ease: "elastic",
        stagger: 0.3,
      }
    )

  if (reverse) {
    popup_tl.reverse()
  } else {
    popup_tl.play()
  }
}
