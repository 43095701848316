import React from "react"
import { Link } from "gatsby"
import MenuArrow from "../../icons/menu-arrow"
import PropTypes from "prop-types"

const MainMenu = ({ pageUrl }) => {
  const items = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Mission",
      url: "/mission",
    },
    {
      name: "Evaluation Criteria",
      url: "/evaluation-criteria",
    },
    {
      name: "Judging Panel",
      url: "/judging-panel",
    },
    {
      name: "About Organizer",
      url: "/about-organizer",
    },
    {
      name: "Inquiry",
      url: "/inquiry",
    },
  ]
  const properties = [
    {
      color: "bg-blue-light",
      padding_right: "md:pr-[152px]",
    },
    {
      color: "bg-green",
      padding_right: "md:pr-[455px]",
    },
    {
      color: "bg-blue",
      padding_right: "md:pr-[608px]",
    },
    {
      color: "bg-black",
      padding_right: "md:pr-[306px]",
    },
    {
      color: "bg-red",
      padding_right: "md:pr-0",
    },
  ]

  items.splice(
    items.findIndex(item => item.url === pageUrl),
    1
  )

  const showItems = items.map((item, i) => {
    return (
      <li
        key={i}
        className={` md:mb-0 mb-2 block ${properties[i].padding_right} `}
      >
        <Link
          to={item.url}
          className={`group md:pb-[21px] pb-[17px] md:pt-6 pt-5 md:px-4  pl-12 pr-4 leading-[0px]  md:w-64 inline-block text-left  text-white ${properties[i].color} `}
        >
          {item.name}
          <span
            className={`transform duration-300 ml-6 group-hover:translate-x-3 inline-block`}
          >
            <MenuArrow color="#ffffff" />
          </span>
        </Link>
      </li>
    )
  })
  return (
    <nav className="md:pr-0 font-normal  pl-7 pr-7 pt-40  pb-0 ">
      <ul className="text-right">{showItems}</ul>
    </nav>
  )
}

MainMenu.propTypes = {
  pageUrl: PropTypes.string.isRequired,
}

export default MainMenu
