const send_report = (values = {}) => {
  // let BASE_URL = ""
  // if (typeof window !== "undefined") {
  //   BASE_URL =
  //     window.location.hostname === "localhost"
  //       ? ""
  //       : process.env.GATSBY_FUNCTIONS_ENDPOINT
  // }

 

  return fetch(`/api/send-report`, {
    method: "POST",
    body: JSON.stringify(values),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(res => res.json())
    .then(data => {
      console.log(data)
      if (data.error) {
        return false
      } else {
        return true
      }
    })
    .catch(error => {
      console.log(error)
      return false
    })
}

export default send_report
