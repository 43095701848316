import React, {useState} from 'react'
import ctl from "@netlify/classnames-template-literals"
import WaitlistArrow from "../../icons/waitlist-arrow"
import DownloadPopup from './popup'

const DownloadButton = ({text, extraStyle}) => {
const [isOpen, setIsOpen] = useState(false)
    
const togglePopup = () => {
    setIsOpen(!isOpen);
}
const buttonStyle=ctl(`
group 
bg-red 
text-white 
px-8 
pt-2
md:pt-3
md:pb-5 
pb-3
flex 
items-center 
md:justify-start 
justify-center  
rounded-none 
leading-none 
md:w-auto 
w-full
focus:outline-none
`)
  
    return (
        <section>
        <button className={`${buttonStyle} ${extraStyle}`} onClick={togglePopup}>
            {text} 
            <span className=" transform duration-300 inline-block ml-5  mt-1 group-hover:translate-x-3">
                <WaitlistArrow />
            </span> 
        </button>

        {isOpen && (
            <DownloadPopup
            isOpen={togglePopup}
            closePopup={togglePopup}
            />
        )}
        </section>
    )
}

export default DownloadButton
