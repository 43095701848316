import React from "react"

const PopupClose = () => {
  return (
    <svg
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="md:w-[23px] w-[15px]"
    >
      <path
        d="M21.0312 0.999984L5.43168 17.4629"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M17.0312 0.999984L1.43168 17.4629"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M5.36516 1.86342L21.8281 17.4629"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M0.999923 1.86342L17.4628 17.4629"
        stroke="white"
        strokeWidth="1.72687"
      />
    </svg>
  )
}

export default PopupClose
