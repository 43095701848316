import React from "react"
import PropTypes from "prop-types"

const MenuArrow = ({ color }) => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
    >
      <path
        d="M0.999969 1.15482L6.90366 6.74888"
        stroke={color}
        strokeWidth="1.72687"
      />
      <path
        d="M1.3096 10L6.90366 4.09634"
        stroke={color}
        strokeWidth="1.72687"
      />
    </svg>
  )
}

MenuArrow.propTypes = {
  color: PropTypes.string,
}

export default MenuArrow
