import React from "react"

const WaitlistArrow = () => {
  return (
    <svg
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="md:w-auto w-5 "
    >
      <path
        d="M0 13.5198L28.9251 13.5198"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M12.0882 1.43172L28.5511 17.0312"
        stroke="white"
        strokeWidth="1.72687"
      />
      <path
        d="M12.9516 26.0972L28.5511 9.63432"
        stroke="white"
        strokeWidth="1.72687"
      />
    </svg>
  )
}

export default WaitlistArrow
