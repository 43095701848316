/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import SEO from "./seo"
import MainFooter from "../utils/footer"
// import WaitListForm from "../utils/waitlist-form"
import DownloadButton from "../utils/download-report/button"

import MainMenu from "./menu"

const Layout = ({
  children,
  title,
  ignoreSiteName,
  url,
  ignoreWaitlistForm,
}) => {
  return (
    <>
      <SEO title={title} ignoreSiteName={ignoreSiteName} />

      <main>{children}</main>

      {!ignoreWaitlistForm && <div className="max-w-screen-md md:pl-10 pl-7 lg:ml-64  pr-7 mt-12">
        <DownloadButton text="Download report"/>
      </div> }
      <MainMenu pageUrl={url} />
      <MainFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default Layout
